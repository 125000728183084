html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

body {
  font-family: "Jost", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.44;
  letter-spacing: 0;
  font-weight: 400;
  color: #888;
  background-color: #1d1b19;
  overflow-x: hidden !important;
  user-select: none;
}

.link {
  cursor: pointer;
}

/* Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 0.3rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1d1b19;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #987750;
  border-radius: 0.5rem;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 0rem;
    display: none;
  }
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: rgb(79, 255, 9);
} */

/* .swiper-slide-duplicate-prev {
  opacity: 0;
}

.swiper-slide-duplicate-next {
  opacity: 0;
}

.swiper-slide-duplicate-next {
  opacity: 0;
} */

/* .swiper-cube .swiper-slide-shadow-bottom,
.swiper-cube .swiper-slide-shadow-left,
.swiper-cube .swiper-slide-shadow-right,
.swiper-cube .swiper-slide-shadow-top {
  z-index: 0;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
} */

.height-100 {
  height: 100%;
  height: 100vh;
  height: 100dvh;
  height: 100svh;
}

.swiper {
  height: 100%;
}

.swiper-slide {
  height: auto !important;
}
.swiper-content {
  height: 100% !important;
}
