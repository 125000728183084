.item {
  position: relative;
}

.item:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 50px;
  left: -1px;
  top: 50%;
  margin-top: -25px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.5s 1s ease;
}
